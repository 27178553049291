<script setup>
import {onMounted, ref} from 'vue';

const props = defineProps({
    modelValue: String,
    placeholder: String | undefined,
    hasError: Boolean | undefined,
    regex: String | undefined,
});

const emits = defineEmits(['update:modelValue', 'focus']);

const input = ref(null);

onMounted(() => {
    if (input.value.hasAttribute('autofocus')) {
        input.value.focus();
    }
});

defineExpose({focus: () => input.value.focus()});

const updateModelValue = (event) => {
    let value = event.target.value;
    if (props.regex) {
        value = value.replaceAll(props.regex, '');
        event.target.value = value;
    }
    emits('update:modelValue', value)
}

</script>

<template>
    <input
        ref="input"
        class="border-gray-300  dark:border-slate-200 focus:border-blue-600 dark:focus:border-blue-600 dark:border-slate-600 focus:border-blue-600 dark:focus:border-blue-600 focus:ring-blue-600 rounded-md shadow-sm
placeholder:text-gray-400 dark:bg-dark-blue dark:text-slate-200"
        :class="{'border-red-300': hasError }"
        :value="modelValue"
        @input="updateModelValue"
        @focus="$emit('focus')"
        type="text"
        :placeholder="placeholder"
    >
</template>
